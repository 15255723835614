import { FormItem } from "./FormItem";
import React, { useMemo, useRef, useState } from "react";
import {
  DateRange,
  DateRangePicker as MUIDateRangePicker,
  LocalizationProvider as LocalizationProviderPro,
} from "@mui/x-date-pickers-pro";

import { Stack, TextField } from "@mui/material";
import { asKey } from "./asKey";
import { DateTime } from "luxon";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";

interface IDatePickerProps {
  date: DateTime;
  setDate: Function;
  label: string;
  name: string;
  maxDateTime?: DateTime;
  minDateTime?: DateTime;
  disabled?: boolean;
}

interface DateRangePickerProps {
  dateRange: DateRange<DateTime>;
  setDateRange: Function;
  label: string;
  name: string;
}

function DateRangePicker(props: DateRangePickerProps) {
  const { dateRange, setDateRange, label } = props;
  // console.log(dateRange)
  return (
    <LocalizationProviderPro dateAdapter={AdapterLuxon}>
      <MUIDateRangePicker
        value={dateRange}
        onChange={(newRange) => {
          console.log(newRange);
          setDateRange(newRange);
        }}
        label={label}
        disableFuture={false}
        disableMaskedInput
        mask="____/__/__"
        inputFormat={"yyyy-MM-dd"}
        renderInput={(startProps, endProps) => (
          <Stack spacing={2}>
            <TextField {...startProps} size="small" />
            <TextField {...endProps} size="small" />
          </Stack>
        )}
      />
    </LocalizationProviderPro>
  );
}

export function DateTimePicker(props: IDatePickerProps) {
  const { date, setDate, label, maxDateTime, minDateTime } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <LocalizationProvider
        dateFormats={{ fullDateTime: "YYYY-MM-DD hh:mm" }}
        dateAdapter={AdapterLuxon}
      >
        <DesktopDateTimePicker
          value={date}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(newDate) => {
            console.log(newDate);
            // @ts-ignore
            setDate(newDate);
          }}
          label={label}
          mask={"____/__/__ __:__"}
          disableFuture={false}
          ampm={false}
          ampmInClock={false}
          maxDateTime={maxDateTime}
          minDateTime={minDateTime}
          renderInput={(props) => (
            <TextField
              onClick={() => setOpen(true)}
              name={props.name}
              size="small"
              {...props}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export function DateRangeInput(props: {
  name: string;
  range: DateRange<DateTime>;
  label?: string;
  setRange: React.Dispatch<React.SetStateAction<DateRange<DateTime>>>;
}) {
  const key = asKey(props.name);
  return (
    <FormItem>
      {/*<label style={{alignSelf: "center"}}>{props.label ?? props.name}: </label>*/}
      {/* @ts-ignore */}
      <DateRangePicker
        key={key}
        name={key}
        dateRange={props.range}
        setDateRange={props.setRange}
        label={props.label ?? props.name}
      />
      {/*<input readOnly required hidden name={key} value={props.date.toISO()}/>*/}
    </FormItem>
  );
}

export function DateInput(props: {
  name: string;
  date: DateTime;
  label?: string;
  setDate: (value: ((prevState: DateTime) => DateTime) | DateTime) => void;
}) {
  const key = asKey(props.name);
  return (
    <FormItem>
      {/*<label style={{alignSelf: "center"}}>{props.label ?? props.name}: </label>*/}
      {/* @ts-ignore */}
      <DateTimePicker
        key={key}
        name={key}
        date={props.date}
        setDate={props.setDate}
        label={props.label ?? props.name}
      />
      {/*<input readOnly required hidden name={key} value={props.date.toISO()}/>*/}
      <input
        readOnly
        required
        hidden
        name={"timezone"}
        value={props.date.zoneName}
      />
    </FormItem>
  );
}
