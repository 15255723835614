import useSWR from "swr";
import axios from "axios";
import { Suggestions } from "../../../lib/bookingQueries";
import { getEmptyBooking } from "../../../lib/getEmptyBooking";
import Booking = DBSchema.Booking;

// @ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json());

export function useBookings(defaultValue, isReplay?: boolean) {
  const { data, error } = useSWR(
    `/api/booking/list${isReplay ? "?replay=true" : ""}`,
    fetcher,
    {
      refreshInterval: 2500,
      fallbackData: defaultValue,
      refreshWhenOffline: true,
    }
  );
  if (error) console.error(error);
  return data;
}

export function useSuggestions(defaultValue?): Suggestions {
  const { data, error } = useSWR("/api/getFormSuggestions", fetcher, {
    fallbackData: defaultValue ?? getEmptySuggestions(),
    dedupingInterval: 1000,
  });

  if (error) console.error(error);

  return data;
}

export function getEmptySuggestions(): Suggestions {
  return {
    tests: [],
    accounts: [],
    bookedby: [],
    bookingIDs: [],
    categories: { internal: [], nonInternal: [] },
    companies: [],
    departments: [],
    drivers: [],
    trackers: [],
  };
}

export function useTracks(defaultValue) {
  const { data, error } = useSWR("/api/getTracks", fetcher, {
    fallbackData: defaultValue,
  });

  if (error) console.error(error);

  return data.tracks;
}

export function useInternalBookings(defaultValue) {
  const { data, error } = useSWR("/api/booking/getInternalBookings", fetcher, {
    fallbackData: defaultValue,
    refreshInterval: 2500,
  });

  if (error) console.error(error);

  return data;
}

export function useReleasableTrackers(defaultValue) {
  const { data, error } = useSWR(
    "/api/booking/getReleasableTrackers",
    fetcher,
    {
      fallbackData: defaultValue,
      refreshInterval: 2500,
    }
  );

  if (error) console.error(error);

  return data;
}

export function useBooking(vtuid: string, replay?: boolean): Booking {
  const { data, error } = useSWR(
    "/api/booking/" + vtuid + (!!replay ? "?replay=true" : ""),
    fetcher,
    {
      fallbackData: getEmptyBooking(vtuid),
      refreshInterval: 1000,
    }
  );
  if (error) console.error(error);
  return data;
}

export async function deleteBooking(id) {
  return await axios.post("/api/booking/delete", { id });
}

export async function releaseTrackers(trackers: string[]) {
  // console.log(trackers)
  return axios.post("/api/booking/release", { trackers: trackers });
}
