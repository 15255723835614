import Head from "next/head";
import styles from "../styles/Reports.module.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Booking = DBSchema.Booking;

import { FormItem } from "../components/FormItem";
import { DateRangeInput,DateInput } from "../components/DateInput";
import {
  BasicCheckbox,
  BasicDropDown,
  BasicInput,
  ControlledCheckbox,
  ControlledDropDown,
  ControlledTextbox,
} from "../components/FormComponents";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  getBookingByID,
  getBookingByVTUID,
  getFormSuggestions,
  getTracks,
} from "../lib/bookingQueries";
import { DateTime } from "luxon";
import { getEmptyBooking } from "../lib/getEmptyBooking";
import getConfig from "../src/config/config";
import { DateRange } from "@mui/lab";
import BookingStatus = DBSchema.BookingStatus;
import { useSuggestions } from "../src/components/CheckinsPage/hooks";
// import {TooltipButton} from "../src/components/CheckinsPage/ActionDialog";
// import booking = DBSchema.booking;
// import Booking = DBSchema.Booking;
// import BookingStatus = DBSchema.BookingStatus;

// import "../components/iframeResizer.contentWindow.min.js"

export async function getServerSideProps(context): Promise<any> {
  console.log(context);
  const { id, vtuid } = context.query;
  const data = await getFormSuggestions();
  const tracks: string[] = (await getTracks())
    .filter((track) => track.trackprefix != null)
    .map((track) => track.trackprefix)
    .filter((track) => track !== "HPG" && track !== "HPGSite");
  const bookings: Booking[] = id
    ? await getBookingByID(id)
    : await getBookingByVTUID(vtuid);
  const booking: Booking =
    JSON.parse(JSON.stringify(bookings))[0] ?? getEmptyBooking(vtuid ?? "");
  //@ts-ignore
  if (typeof booking.tracks === "string") {
    booking.tracks = JSON.parse(booking.tracks);
  }

  const config = getConfig();

  if (!Array.isArray(booking?.tracks) && booking) booking.tracks = [];

  return {
    props: { suggestions: data, tracks, booking, vtuid: vtuid ?? "", config }, // will be passed to the page component as props
  };
}

interface BookingPageProps {
  suggestions: any;
  tracks: string[];
  booking: Booking;
  vtuid?: string;
  callback?: Function;
  config: any;
  action?: string;
  limitEditing?: boolean;
  isReplay?: boolean;
}

const BookingPage = (props: BookingPageProps) => {
  console.log(props);
  return (
    <div>
      <Head>
        <title>Booking</title>
      </Head>

      <main className={styles.main} style={{ padding: 0 }}>
        <BookingForm {...props} />
      </main>
    </div>
  );
};

export function BookingForm(props: BookingPageProps) {
  // const [startDate, setStartDate] = useState(DateTime.fromISO(props?.booking?.startdate))
  // const [endDate, setEndDate] = useState(DateTime.fromISO(props?.booking?.enddate))
  const { tracks, limitEditing, isReplay } = props;

  const suggestions = useSuggestions(props.suggestions);

  const [dateRange, setDateRange] = useState<DateRange<DateTime>>([
    DateTime.fromISO(props?.booking?.startdate),
    DateTime.fromISO(props?.booking?.enddate),
  ]);
  const [selectedTracks, setSelectedTracks] = useState([
    ...(props.booking.tracks ?? []),
  ]);
  const [id, setID] = useState<string | number>(props.booking?.id ?? 0);
  const [status, setStatus] = useState(props.booking.status);

  const [allowSubmit, setAllowSubmit] = useState(true);
  const [formValues, setFormValues] = useState<Booking>({
    ...props.booking,
    vtuid: props.vtuid ?? props.booking?.vtuid,
  });
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const [endDate, setEndDate] = useState(DateTime.fromISO(props?.booking?.enddate));
  const [startDate, setStartDate] = useState(DateTime.fromISO(props?.booking?.startdate));

  //change this for only using date
  const useTime = process.env.NEXT_PUBLIC_CHECKINS_USE_TIME === "true";
  console.log("useTime = " + useTime);

  // @ts-ignore
  const ref: React.MutableRefObject<HTMLFormElement> = useRef();

  const requiredFields = useMemo(() => getRequiredFormFields(status), [status]);

  // @ts-ignore
  const requiredStatus: Required = useMemo(() => {
    return Object.fromEntries(
      Object.entries(requiredFields).map(([key, val]) => {
        if (!ref.current) return [key, false];
        return [key, val ? !!formValues[key] : true];
      })
    );
  }, [requiredFields, ref.current, status, formValues]);

  useEffect(() => {
    const okay = !Object.values(requiredStatus).some((val) => !val);
    setAllowSubmit(okay);
  }, [formValues, requiredFields, requiredStatus]);

  const categories = props.booking.internal
    ? suggestions.categories.internal
    : suggestions.categories.nonInternal;
  const updateValue = (name, val) =>
    setFormValues((vals) => ({ ...vals, [name]: val }));

  useEffect(() => {
    updateValue("status", status);
    // setFormValues(val => ({...val, vtuid: status === "Open" || status === "Closed" ? "" : val.vtuid}))
  }, [status]);

  useEffect(() => {
    console.log("props change");
    console.log(props.booking);
    setFormValues(props.booking);
  }, [JSON.stringify(props.booking)]);

  console.log(formValues);

  const showErrors = submitAttempted && !allowSubmit;
  const displayTMSID =
    props.booking.id ?? 0 > 0 ? `${props.booking.id}` : "N/A";

  // const statusOptions: BookingStatus[] = displayTMSID === "N/A" ? ['Open', 'Active'] : ['Open', 'Closed', 'Active', 'Expired']
  const statusOptions: BookingStatus[] = getStatusOptions(props.booking);
  //
  // const formData = new FormData(ref.current)
  // const allowSubmit = [...formData.entries()].filter(([key,]) => requiredFields[key]).some(([key, val]) => !val)

  function handleForm(e) {
    console.log("SUBMIT CLICKED");
    e.preventDefault();
    setSubmitAttempted(true);
    if (!ref.current.checkValidity()) {
      setAllowSubmit(false);
      return;
    }
    setLoading(true);
    const rawFormData = new FormData(ref.current);
    console.log(rawFormData);

    if(!useTime){
      // @ts-ignore
      rawFormData.set("startdate", dateRange[0].toUTC().toISO());
      // @ts-ignore
      if (dateRange[1]) {
        rawFormData.set(
            "enddate",
            dateRange[1].set({ hour: 23, minute: 59, second: 59 }).toUTC().toISO()
        );
      }
    }
    else{
      // @ts-ignore
      rawFormData.set("startdate", startDate.set({second:0}).toUTC().toISO());
      // @ts-ignore
      rawFormData.set("enddate", endDate.set({second:0}).plus({ minute:30 }).toUTC().toISO());
    }

    // Filter out form items that ARE NOT tracks
    const parsedTracks = JSON.stringify(
      [...rawFormData.entries()]
        .filter(([name]) => tracks.includes(name))
        .map(([name]) => name)
    );
    // Filter out form items that ARE tracks, insert tracks as a single array of track names
    const formDataEntries = [
      ...[...rawFormData.entries()].filter(([name]) => !tracks.includes(name)),
      ["tracks", parsedTracks],
    ];

    const formDataBadCheckboxes = Object.fromEntries(formDataEntries);

    const formData = {
      ...props.booking,
      ...formDataBadCheckboxes,
      alarms: formDataBadCheckboxes["alarms"] == "on",
      exclusive: formDataBadCheckboxes["exclusive"] == "on",
      status: status,
    };

    fetch("/api/booking/updateBooking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((res) => {
      setLoading(false);
      setSuccess(res?.status == 200);
      fetch("/api/alarms/doUpdate").catch(console.error);
      // window.parent.close()
      window.parent.postMessage("done", window.parent.location.origin);
      if (props.callback) {
        props.callback(res);
      }
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      res.json().then((data) => {
        if (!(id > 0)) {
          setID(data.id);
        }
      });
    });
  }

  // @ts-ignore
  return (
    <>
      <form
        ref={ref}
        className={styles.bookingForm}
        action="/api/booking/updateBooking"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid>
          <Grid container item justifyContent="center">
            {!props.booking.internal && (
              <SidePanel
                tracks={props.tracks}
                vtuid={props.booking?.vtuid ?? props.vtuid}
                limitEditing={limitEditing}
                element={(track) => (
                  <ControlledCheckbox
                    value={selectedTracks.includes(track)}
                    setValue={(val) => {
                      if (selectedTracks.includes(track)) {
                        setSelectedTracks((tracks) =>
                          tracks.filter((item) => item !== track)
                        );
                      } else {
                        setSelectedTracks((tracks) => [...tracks, track]);
                      }
                    }}
                    key={track}
                    name={track}
                    label={track}
                  />
                )}
                selectAllTracks={(val) =>
                  setSelectedTracks(() => {
                    console.log(val);
                    return val ? props.tracks : [];
                  })
                }
                booking={props.booking}
                dateProps={{ dateRange, setDateRange, startDate, endDate, setStartDate, setEndDate, useTime }}
                action={props.action}
                selectedTracks={selectedTracks}
                requiredFields={requiredFields}
              />
            )}
            <Stack>
              <input
                name={"id"}
                hidden
                value={id}
                onChange={(e) => setID(e.target.value)}
              />

              {/*{!props.booking.internal && <BookingIDInput booking={props.booking} setAllowSubmit={setAllowSubmit}*/}
              {/*                                            disabled={limitEditing} required={requiredFields.bookingid}*/}
              {/*                 disallowedNames={suggestions.bookingIDs.map(id => id.bookingid)}/>}*/}
              {!props.booking.internal && (
                <ControlledDropDown
                  name="bookingid"
                  disabled={limitEditing}
                  defaultValue={props.booking.bookingid}
                  options={[
                    ...suggestions.bookingIDs
                      .map((id) => id.bookingid)
                      .filter((id) => id.length > 0)
                      .sort(),
                  ]}
                  required={requiredFields.bookingid}
                  value={formValues.bookingid}
                  updateValue={updateValue}
                  helperText={
                    showErrors && !requiredStatus.bookingid
                      ? "This field is requried"
                      : undefined
                  }
                  label={"Booking ID"}
                />
              )}
              {/*<Tooltip arrow title={status === "Open" || status === "Closed" ? "A "+status+" booking cannot be assigned a tracker, change status to Active to assign a tracker." : undefined}>*/}
              {/*<span>*/}

              <ControlledDropDown
                name="vtuid"
                disabled={limitEditing || status === "Closed"}
                options={[
                  ...suggestions.trackers
                    .map((tracker) => tracker.vtuid)
                    .filter((id) => id.length > 3)
                    .sort(),
                ]}
                disableCustom
                required={requiredFields.vtuid}
                helperText={
                  showErrors && !requiredStatus.vtuid
                    ? "This field is required"
                    : undefined
                }
                value={formValues.vtuid}
                updateValue={updateValue}
                label={"VTU ID"}
              />
              {/*</span>*/}
              {/*</Tooltip>*/}

              <ControlledDropDown
                name={"account"}
                disabled={limitEditing}
                value={formValues.account}
                updateValue={updateValue}
                options={[
                  ...suggestions.accounts.map((account) => account.account),
                ]}
              />
              <ControlledDropDown
                name={"company"}
                disabled={limitEditing}
                value={formValues.company}
                updateValue={updateValue}
                options={[
                  ...suggestions.companies.map((company) => company.company),
                ]}
              />
              <ControlledDropDown
                name="department"
                value={formValues.department}
                updateValue={updateValue}
                disabled={limitEditing}
                options={suggestions.departments
                  .map((dep) => dep.department)
                  .filter((dep) => dep.length > 0)}
              />
              {!props.booking.internal && (
                <>
                  <ControlledDropDown
                    value={formValues.test}
                    updateValue={updateValue}
                    disabled={limitEditing}
                    options={suggestions.tests
                      .map((test) => test.test)
                      .filter((test) => test.length > 1)}
                    defaultValue={props.booking?.test}
                    hidden={props.booking.internal}
                    name="test"
                  />
                  <ControlledDropDown
                    disabled={limitEditing}
                    options={suggestions.drivers
                      .map((driver) => driver.driver)
                      .filter((name) => name.length > 1)}
                    value={formValues.driver}
                    updateValue={updateValue}
                    required={requiredFields.driver}
                    helperText={
                      showErrors && !requiredStatus.driver
                        ? "This field is requried"
                        : undefined
                    }
                    name="driver"
                  />
                </>
              )}
              <ControlledDropDown
                disabled={limitEditing}
                value={formValues.bookedby}
                updateValue={updateValue}
                options={suggestions.bookedby
                  .map((bb) => bb.bookedby)
                  .filter((bb) => bb.length > 0)}
                name="bookedby"
                label={"Booked By"}
              />

              <ControlledTextbox
                value={formValues.regno}
                updateValue={updateValue}
                name="regno"
                disabled={limitEditing}
              />
              <ControlledDropDown
                name={"vehiclecategory"}
                label={"Vehicle Category"}
                defaultValue={props.booking?.vehiclecategory}
                disabled={limitEditing}
                disableCustom
                required={
                  requiredFields.vehiclecategory || formValues.vtuid !== ""
                }
                value={formValues.vehiclecategory}
                updateValue={updateValue}
                helperText={
                  showErrors && !requiredStatus.vehiclecategory
                    ? "This field is requried"
                    : undefined
                }
                options={[...categories]}
              />

              {/*<VehicleCategory booking={props.booking} */}
              {/*                 suggestions={suggestions}*/}
              {/*                 disabled={limitEditing}*/}
              {/*                 required={requiredFields.vehiclecategory}*/}
              {/*                 helperText={showErrors && !requiredStatus.vehiclecategory ? "This field is requried" : undefined}*/}
              {!props.booking.internal && (
                <>
                  <BasicDropDown
                    defaultValue={props.booking?.tlevel ?? "T1"}
                    name={"tlevel"}
                    label={"T-Level"}
                    disabled={limitEditing}
                    options={["T1", "T2", "T3"]}
                    required={true}
                    disableCustom
                    hidden={props.booking.internal}
                  />

                  <Grid item container>
                    <Grid item xs={8}>
                      <ControlledDropDown
                        name={"status"}
                        disabled={true}
                        // defaultValue={props.booking?.status ?? "Open"}

                        value={status}
                        updateValue={(name, val: BookingStatus) =>
                          setStatus(val)
                        }
                        options={statusOptions}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {/*    <FormItem>*/}
                      <BasicDropDown
                        name={"TMS ID"}
                        defaultValue={displayTMSID}
                        options={[displayTMSID]}
                        disabled={true}
                      />
                      {/*</FormItem>*/}
                    </Grid>
                  </Grid>
                </>
              )}
            </Stack>
          </Grid>
          <Grid item container>
            <TextField
              fullWidth
              label={"Comments"}
              sx={{ marginX: 2, marginTop: 1 }}
              multiline
              name={"comments"}
              maxRows={3}
              minRows={1}
              defaultValue={props?.booking?.comments}
              disabled={limitEditing}
            />
          </Grid>
          <Grid item container justifyContent={"center"}>
            <FormItem>
              <div className={styles.submit} data-iframe-height="">
                <Button
                  sx={{ margin: 1 }}
                  color={"error"}
                  variant={"outlined"}
                  onClick={() => {
                    if (props.callback) {
                      props.callback();
                    }
                    // window.parent.close()
                    window.parent.postMessage(
                      "done",
                      window.parent.location.origin
                    );
                  }}
                >
                  Cancel
                </Button>
                {/*{loading ? <><CircularProgress sx={{width: "16px"}} /> Please wait...</> :*/}
                <Tooltip
                  title={
                    !formValues.bookingid &&
                    displayTMSID === "N/A" &&
                    !formValues.internal &&
                    limitEditing
                      ? "Please create a check-in on the check-ins page"
                      : ""
                  }
                >
                  <span>
                    <Button
                      sx={{ margin: 1, minWidth: "100px" }}
                      color={"success"}
                      variant={"contained"}
                      onClick={(e) => handleForm(e)}
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      disabled={
                        !!isReplay ||
                        (submitAttempted && !allowSubmit) ||
                        (!formValues.bookingid &&
                          displayTMSID === "N/A" &&
                          !formValues.internal &&
                          limitEditing)
                      }
                    >
                      {loading ? (
                        <CircularProgress color={"info"} size={24} />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </span>
                </Tooltip>
                {/*}*/}
                {/*<p hidden={!success} className={styles.successText}>Done!</p>*/}
              </div>
            </FormItem>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function SidePanel(props: {
  tracks: string[];
  element: (track: string) => JSX.Element;
  booking: Booking;
  vtuid: string;
  dateProps: {
    dateRange: DateRange<DateTime>;
    setDateRange: React.Dispatch<React.SetStateAction<DateRange<DateTime>>>;
    startDate: DateTime;
    endDate: DateTime;
    setStartDate: React.Dispatch<React.SetStateAction<DateTime>>;
    setEndDate: React.Dispatch<React.SetStateAction<DateTime>>;
    useTime: boolean;
  };
  action?: string;
  selectAllTracks: (state: boolean) => void;
  selectedTracks: string[];
  limitEditing?: boolean;
  requiredFields: Required;
}) {
  // console.log(props.tracks)
  const trackHalf1 = props.tracks.filter((val, i, arr) => i < arr.length / 2);
  const trackHalf2 = props.tracks.filter((val) => !trackHalf1.includes(val));

  const { dateRange, setDateRange, startDate, endDate, setStartDate, setEndDate, useTime} = props.dateProps;

  return (
    <Stack marginX={2} justifyContent={"space-around"}>
      <Grid
        container
        direction={"column"}
        justifyContent={"flex-start"}
        sx={{ marginTop: -1, marginBottom: -2 }}
      >
        {!!props?.action && (
          <Typography fontWeight={"bold"} variant={"h5"}>
            {!props.limitEditing && props.action}
          </Typography>
        )}
        {/*<Typography variant="subtitle1">{props.booking.bookingid}</Typography>*/}
      </Grid>

      <Paper sx={{ padding: 1 }} elevation={3}>
        <Typography fontWeight={"bold"}>Booking Date</Typography>
        {!useTime &&  <DateRangeInput
          name={"dateRange"}
          label={"Date Range"}
          range={dateRange}
          setRange={setDateRange}
        />}
        {useTime && <DateInput name={"startdate"} label={"Start Date"} date={startDate} setDate={setStartDate}/>}
        {useTime && <DateInput name={"enddate"} label={"End Date"} date={endDate} setDate={setEndDate}/>}
      </Paper>

      <Paper sx={{ paddingX: 2, paddingY: 0 }} elevation={3}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Typography fontWeight={"bold"}>Tracks</Typography>
          <ControlledCheckbox
            name={"selectAll"}
            value={props.selectedTracks.length === props.tracks.length}
            indeterminate={
              !(props.selectedTracks.length === props.tracks.length) &&
              props.selectedTracks.length > 0
            }
            label={"Select All"}
            setValue={props.selectAllTracks}
          />
        </Grid>
        <Grid container>
          <Stack>{trackHalf1.map(props.element)}</Stack>
          <Stack>{trackHalf2.map(props.element)}</Stack>
        </Grid>
        {/*{props.tracks.map(props.element)}*/}
      </Paper>
      <Paper sx={{ paddingX: 2, paddingY: 0.5 }} elevation={3}>
        <Typography fontWeight={"bold"}>Options</Typography>
        <BasicCheckbox
          className={styles.bookingOption}
          name={"alarms"}
          label={"Select to enable Alarms"}
          defaultValue={props.booking?.alarms ?? true}
        />
        <BasicCheckbox
          className={styles.bookingOption}
          name={"exclusive"}
          label={"Select for Exclusive Check-in"}
          defaultValue={props.booking?.exclusive}
        />
      </Paper>
    </Stack>
  );
}

function getStatusOptions(booking: Booking): BookingStatus[] {
  if (!(booking.id ?? 0 > 0)) return ["Open", "Active"];
  switch (booking.status) {
    case "Closed":
      return ["Closed"];
  }
  return ["Open", "Closed", "Active", "Expired"];
}

function getRequiredFormFields(status: BookingStatus): Required {
  const open: Required = {
    tracks: true,
    bookingid: true,
    driver: true,
    vtuid: false,
    vehiclecategory: false,
  };
  switch (status) {
    case "Open":
      return open;
    case "Active":
      return {
        ...open,
        vtuid: true,
        vehiclecategory: true,
      };
    default:
      return {
        tracks: false,
        bookingid: true,
        driver: false,
        vtuid: false,
        vehiclecategory: false,
      };
  }
}

interface Required {
  tracks: boolean;
  bookingid: boolean;
  driver: boolean;
  vtuid: boolean;
  vehiclecategory: boolean;
}

export default BookingPage;

// @ts-ignore
// const fetcher = (...args) => fetch(...args).then((res) => res.json())

// function useUnassignedTrackers(url, defaultValue) {
//     const { data, error } = useSWR(url, fetcher, {
//         fallbackData: defaultValue
//     })
//     if (error) console.error(error)
//     console.log(data)
//     return data.map(item => item.value)
// }
//
// function useCategories(url, defaultValue) {
//     const { data, error } = useSWR(url, fetcher, {fallbackData: defaultValue})
//     if(error) console.error(error)
//     return data.map(item => item.value)
// }
