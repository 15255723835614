import Booking = DBSchema.Booking;

import { DateTime } from "luxon";

export function getEmptyBooking(vtuid?): Booking {
  return {
    id: 0,
    // id: parseInt((Math.random()*1000000000).toFixed(0)),
    account: "",
    alarms: true,
    bookedby: "",
    bookingid: "",
    comments: "",
    company: "",
    department: "",
    driver: "",
    enddate: DateTime.now().plus({ days: 1 }).toISO(),
    exclusive: false,
    regno: "",
    startdate: DateTime.now().toISO(),
    status: "Open",
    test: "",
    tlevel: "T1",
    tracks: [],
    vehiclecategory: "",
    vtuid: vtuid ?? "",
    internal: false,
    alarmsList: [],
  };
}

export function getEmptyInternalBooking(vtuid?): Booking {
  return {
    ...getEmptyBooking(vtuid),
    startdate: "1990-12-03T07:00:00.000Z",
    enddate: "9999-12-03T07:00:00.000Z",
    status: "Active",
    internal: true,
  };
}
